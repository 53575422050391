

// default values
// segment_by
export const DISTANCE_DEFAULT_UNIT = 'ft';
export const DISTANCE_DEFAULT_AMOUNT = 20;
export const TIME_DEFAULT_AMOUNT = 'hour';
export const ENTRY_FIELD_DEFAULT_UNIT = 'job_name';


// template name
export const TEMPLATE_NAME_SECTION = 'template_name_section';
export const TEMPLATE_NAME = 'template_name';



// sub values
export const COLUMN_SORT_ORDER = 'column_sort_order';
export const FORMAT = 'format';
export const AMOUNT = 'amount';
export const UNITS = 'unit'; //removed s to match backend
export const CREATE_EXCEPTION = 'create_exception';
export const MINIMUM_VALUE = 'lowLimit';
export const MAXIMUM_VALUE = 'highLimit';
export const EXCEPTION_ON_CHANGE = 'warnOnChange';
// Template Type
export const TEMPLATE_TYPE = 'template_type';
export const APPLICATION = 'paint';
export const GRINDER = 'grinder';

// Segment By possible values

export const SEGMENT_BY = 'segment_by';
export const NONE = 'none';
export const DISTANCE = 'distance';
export const DISTANCE_AMOUNT = 'segmentDistance';
export const DISTANCE_UNIT = 'segmentDistanceUnit';
export const PATTERN_CHANGES = 'patternChange';
export const TIME = 'time';
export const TIME_AMOUNT = 'time_amount';
export const LINE_TYPE = 'lineType';
export const ENTRY_FIELD = 'loggingParameter';
export const ENTRY_FIELD_VALUE = 'loggingParameter_value';
// create new segment when counters are cleared
// split left and right side data
export const NEW_SEGMENTS_WHEN_CLEARED = 'new_segments_when_cleared';
export const SPLIT_LEFT_RIGHT_SEGMENTS = 'split_left_right_segments';

// Truck Information
// defaults
export const TRUCK_INFORMATION = 'truck_information';
export const SERIAL_NUMBER = 'truckID';
export const TRUCK_NAME = 'truckName';

// date_time
// defaults
export const DATE_TIME = 'date_time';
export const START_DATE_TIME = 'startDateTime';
export const END_DATE_TIME = 'endDateTime';
export const TOTAL_TIME = 'totalTime';
export const PAINTING_TIME = 'paintingTime';
// advancedOptions
export const DATE = 'date';
export const START_TIME = 'startTime';
export const STOP_TIME = 'stopTime';


// entry_fields
// defaults
export const ENTRY_FIELDS = 'Entry Fields';
export const JOB_NAME = 'Job Name';
export const YELLOW_MATERIAL_BATCH = 'Yellow Material Batch';
export const WHITE_MATERIAL_BATCH = 'White Material Batch';
export const BEAD_1_BATCH = 'Bead 1 Batch';
export const PROJECT_NAME = 'Project Name';
export const PROJECT_NUMBER = 'Project Number';
// advancedOptions
export const SYMBOL = 'Symbol';
export const TRAVEL_DIRECTION = 'Travel Direction';
export const PAINT_TYPE = 'Paint Type';
export const BLACK_MATERIAL_BATCH = 'Black Material Batch';
export const BEAD_1_TYPE = 'Bead 1 Type';
export const BEAD_1_GUN_TYPE = 'Bead 1 Gun Type';
export const BEAD_2_TYPE = 'Bead 2 Type';
export const BEAD_2_GUN_TYPE = 'Bead 2 Gun Type';
export const BEAD_2_BATCH = 'Bead 2 Batch';
export const BEAD_3_TYPE = 'Bead 3 Type';
export const BEAD_3_BATCH = 'Bead 3 Batch';
export const BEAD_3_GUN_TYPE = 'Bead 3 Gun Type';
export const YELLOW_ELEMENTS_TYPE = 'Yellow Elements Type';
export const YELLOW_ELEMENTS_BATCH = 'Yellow Elements Natch';
export const YELLOW_ELEMENTS_GUN_TYPE = 'Yellow Elements Gun Type';
export const WHITE_ELEMENTS_TYPE = 'White Elements Type';
export const WHITE_ELEMENTS_BATCH = 'White Element Batch';
export const WHITE_ELEMENTS_GUN_TYPE = 'White Elements Gun Type';
export const LEFT_LINE_TYPE = 'Left Line Type';
export const RIGHT_LINE_TYPE = 'Right Line Type';
export const ROUTE_SYSTEM = 'Route System';
export const ROUTE_NUMBER = 'Route Number';
export const STRIPER_CODE = 'Striper Code';
export const MARKER_TYPE = 'Marker Type';
export const PAINT_VENDOR = 'Paint Vendor';
export const GROOVED_PAVEMENT = 'Grooved Pavement';
export const GROOVED_DEPTH = 'Grooved Depth';
export const RUMBLE_STRIP = 'Rumble Strip';
export const PAINT_TYPE_2 = 'Paint Type 2';
export const EMPLOYEE_NAME = 'Employee Name';
export const START_HWY_SLM = 'Start HWY SLM';
export const END_HWY_SLM = 'End HWY SLM';
export const ROAD_SURFACE_MATERIAL = 'Road Surface Material';

// speed_and_distance
// defaults
export const SPEED_DISTANCE = 'speed_and_distance';
export const AVERAGE_SPEED = 'averageSpeed';
export const LEFT_LANE_DISTANCE = 'leftLaneDist';
export const RIGHT_LANE_DISTANCE = 'rightLaneDist';
// advancedOptions
export const TRAVEL_DISTANCE = 'traveledDist';
export const TOTAL_LANE_DISTANCE = 'totalLaneDist';

// counter_channels_and_rates
// defaults
export const COUNTER_CHANNELS_AND_RATES = 'counter_channels_and_rates';
export const YELLOW_SKIP_DISTANCE = 'yellow_skip_distance';
export const YELLOW_SOLID_DISTANCE = 'yellow_solid_distance';
export const YELLOW_TOTAL_DISTANCE = 'yellow_total_distance';
export const YELLOW_USAGE = 'yellow_usage';
export const YELLOW_THICKNESS = 'yellow_thickness';
export const YELLOW_DRY_THICKNESS = 'yellow_dry_thickness';
export const YELLOW_APP_RATE = 'yellow_app_rate';
export const WHITE_SKIP_DISTANCE = 'white_skip_distance';
export const WHITE_SOLID_DISTANCE = 'white_solid_distance';
export const WHITE_TOTAL_DISTANCE = 'white_total_distance';
export const WHITE_USAGE = 'white_usage';
export const WHITE_THICKNESS = 'white_thickness';
export const WHITE_DRY_THICKNESS = 'white_dry_thickness';
export const WHITE_APP_RATE= 'white_app_rate';
// advancedOptions
export const SKIP_CHANNELS_BY_GUN_NUMBER = 'skipChannelsByGunNumber';
export const SOLID_CHANNELS_BY_GUN_NUMBER = 'solidChannelsByGunNumber';
export const TOTAL_CHANNELS_BY_GUN_NUMBER = 'totalChannelsByGunNumber';
export const SKIP_CHANNELS_BY_WIDTH = 'skipChannelsByGunWidth';
export const SOLID_CHANNELS_BY_WIDTH = 'solidChannelsByGunWidth';
export const TOTAL_CHANNELS_BY_WIDTH = 'totalChannelsByGunWidth';
export const YELLOW_WEIGHT = 'yellow_weight';
export const YELLOW_APP_RATE_WEIGHT = 'yellow_app_rate_weight';
export const WHITE_WEIGHT = 'white_weight';
export const WHITE_APP_RATE_WEIGHT = 'white_app_rate_weight';
export const BLACK_SKIP_DISTANCE = 'black_skip_distance';
export const BLACK_SOLID_DISTANCE = 'black_solid_distance';
export const BLACK_TOTAL_DISTANCE = 'black_total_distance';
export const BLACK_USAGE = 'black_usage';
export const BLACK_WEIGHT = 'black_weight';
export const BLACK_DRY_THICKNESS = 'black_dry_thickness';
export const BLACK_THICKNESS = 'black_thickness';
export const BLACK_APP_RATE = 'black_app_rate';
export const BLACK_APP_RATE_WEIGHT = 'black_app_rate_weight';
export const LEFT_TOTAL_PAINT_WEIGHT = 'left_total_paint_weight';
export const RIGHT_TOTAL_PAINT_WEIGHT = 'right_total_paint_weight';
export const LEFT_TOTAL_APP_RATE_WEIGHT = 'left_total_app_rate_weight';
export const RIGHT_TOTAL_APP_RATE_WEIGHT = 'right_total_app_rate_weight';
export const YELLOW_BAG_COUNT = 'yellow_bag_count';
export const BLACK_BAG_COUNT = 'black_bag_count';
export const WHITE_BAG_COUNT = 'white_bag_count';

// beads
// defaults
export const BEADS = 'beads';
export const BEADS_1_USAGE = 'beads_1_usage';
export const BEADS_1_USAGE_PER_VOLUME = 'beads_1_usage_per_volume';
export const BEADS_1_USAGE_PER_AREA = 'beads_1_usage_per_area';
export const TOTAL_BEADS_USAGE = 'totalBeadUsage';
export const TOTAL_BEADS_USAGE_PER_VOLUME = 'totalBeadUsagePerPaintVolume';
export const TOTAL_BEADS_USAGE_PER_AREA = 'totalBeadUsagePerPaintArea';
// advancedOptions
export const BEADS_1_USAGE_PER_DISTANCE = 'beads_1_usage_per_distance';
export const BEADS_1_USAGE_PER_PAINT_WEIGHT = 'beads_1_usage_per_paint_weight';
export const BEADS_2_USAGE = 'beads_2_usage';
export const BEADS_2_USAGE_PER_VOLUME = 'beads_2_usage_per_volume';
export const BEADS_2_USAGE_PER_DISTANCE = 'beads_2_usage_per_distance';
export const BEADS_2_USAGE_PER_AREA = 'beads_2_usage_per_area';
export const BEADS_2_USAGE_PER_PAINT_WEIGHT = 'beads_2_usage_per_paint_weight';
export const BEADS_3_USAGE = 'beads_3_usage';
export const BEADS_3_USAGE_PER_VOLUME = 'beads_3_usage_per_volume';
export const BEADS_3_USAGE_PER_DISTANCE = 'beads_3_usage_per_distance';
export const BEADS_3_USAGE_PER_AREA = 'beads_3_usage_per_area';
export const BEADS_3_USAGE_PER_PAINT_WEIGHT = 'beads_3_usage_per_paint_weight';
export const TOTAL_BEADS_USAGE_PER_DISTANCE = 'totalBeadUsagePerPaintDist';
export const TOTAL_BEADS_USAGE_PER_PAINT_WEIGHT = 'totalBeadUsagePerPaintWeight';
export const YELLOW_ELEMENTS_USAGE = 'yellow_elements_usage';
export const YELLOW_ELEMENTS_USAGE_PER_VOLUME = 'yellow_elements_usage_per_volume';
export const YELLOW_ELEMENTS_USAGE_PER_DISTANCE = 'yellow_elements_usage_per_distance';
export const YELLOW_ELEMENTS_USAGE_PER_AREA = 'yellow_elements_usage_per_area';
export const YELLOW_ELEMENTS_USAGE_PER_PAINT_WEIGHT = 'yellow_elements_usage_per_paint_weight';
export const WHITE_ELEMENTS_USAGE = 'white_elements_usage';
export const WHITE_ELEMENTS_USAGE_PER_VOLUME = 'white_elements_usage_per_volume';
export const WHITE_ELEMENTS_USAGE_PER_DISTANCE = 'white_elements_usage_per_distance';
export const WHITE_ELEMENTS_USAGE_PER_AREA = 'white_elements_usage_per_area';
export const WHITE_ELEMENTS_USAGE_PER_PAINT_WEIGHT = 'white_elements_usage_per_paint_weight';
export const VISILOK_USAGE = 'visilok_usage';
export const VISILOK_USAGE_PER_VOLUME = 'visilok_usage_per_volume';
export const VISILOK_USAGE_PER_DISTANCE = 'visilok_usage_per_distance';
export const VISILOK_USAGE_PER_AREA = 'visilok_usage_per_area';
export const VISILOK_USAGE_PER_PAINT_WEIGHT = 'visilok_usage_per_paint_weight';
export const AUX_1_TANK_USAGE = 'aux_1_tank_usage';
export const AUX_1_TANK_USAGE_PER_VOLUME = 'aux_1_tank_usage_per_volume';
export const AUX_1_TANK_USAGE_PER_DISTANCE = 'aux_1_tank_usage_per_distance';
export const AUX_1_TANK_USAGE_PER_AREA = 'aux_1_tank_usage_per_area';
export const AUX_1_TANK_USAGE_PER_PAINT_WEIGHT = 'aux_1_tank_usage_per_paint_weight';
export const AUX_2_TANK_USAGE = 'aux_2_tank_usage';
export const AUX_2_TANK_USAGE_PER_VOLUME = 'aux_2_tank_usage_per_volume';
export const AUX_2_TANK_USAGE_PER_DISTANCE = 'aux_2_tank_usage_per_distance';
export const AUX_2_TANK_USAGE_PER_AREA = 'aux_2_tank_usage_per_area';
export const AUX_2_TANK_USAGE_PER_PAINT_WEIGHT = 'aux_2_tank_usage_per_paint_weight';

// temperatures
// defaults
export const TEMPERATURES= 'temperatures';
export const AVERAGE_AIR_TEMPERATURE = 'airTempAvg';
export const AVERAGE_ROAD_TEMPERATURE = 'roadTempAvg';
export const AVERAGE_HUMIDITY = 'humidityAvg';
export const AVERAGE_DEW_POINT = 'dewPointAvg';
// advancedOptions
export const YELLOW_LINE_TEMPERATURE = 'yellow_line_temperature';
export const YELLOW_TANK_TEMPERATURE = 'yellow_tank_temperature';
export const YELLOW_1_MANIFOLD_TEMPERATURE = 'yellow_1_manifold_temperature';
export const YELLOW_2_MANIFOLD_TEMPERATURE = 'yellow_2_manifold_temperature';
export const WHITE_LINE_TEMPERATURE = 'white_line_temperature';
export const WHITE_TANK_TEMPERATURE = 'white_tank_temperature';
export const WHITE_1_MANIFOLD_TEMPERATURE = 'white_1_manifold_temperature';
export const WHITE_2_MANIFOLD_TEMPERATURE = 'white_2_manifold_temperature';
export const BLACK_LINE_TEMPERATURE = 'black_line_temperature';
export const BLACK_TANK_TEMPERATURE = 'black_tank_temperature';
export const BLACK_1_MANIFOLD_TEMPERATURE = 'black_1_manifold_temperature';
export const BLACK_2_MANIFOLD_TEMPERATURE = 'black_2_manifold_temperature';

// geolocation
// defaults
export const GEOLOCATION = 'geolocation';
export const START_GPS = 'startGPS';
export const END_GPS = 'endGPS';
export const START_MILEPOINT = 'startMilepoint';
export const END_MILEPOINT = 'endMilepoint';

// calibrations
// defaults
export const CALIBRATIONS = 'calibrations';
export const DISTANCE_CALIBRATION = 'footageCal';
export const YELLOW_PUMP_CALIBRATION = 'yellow_pump_calibration';
export const WHITE_PUMP_CALIBRATION = 'white_pump_calibration';
export const BLACK_PUMP_CALIBRATION = 'black_pump_calibration';

// miscellaneous
// defaults
export const MISCELLANEOUS = 'miscellaneous';
export const PATTERN = 'pattern';
export const COMMENTS = 'Comment';
export const PAINTED_COLORS = 'paintedColors';
export const PAINTED_SIDES = 'paintedSides';
export const LINE_WIDTH = 'lineWidth';
export const OBSERVED_WIDTH= 'observedWidth';
export const YIELD= 'yield';
export const THICKNESS_TARGET= 'thickness_target';
export const WHITE_TARGET_THICKNESS = 'white_target_thickness';
export const YELLOW_TARGET_THICKNESS = 'yellow_target_thickness';
export const BLACK_TARGET_THICKNESS = 'black_target_thickness';
// advancedOptions
export const TRUCK_WEIGHT = 'truckWeight';
export const ANALOG_INPUTS = 'analog_inputs';
export const ANALOG_OUTPUTS = 'analog_outputs';
export const SEGMENT_EVENT = 'segmentEvent';
export const PHOTOS = 'photos';

