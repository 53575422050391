import Immutable, { List, Map } from 'immutable';

const initState = Immutable.fromJS({
    dateRange:{dates: [], dropdownValue: ''}, 
    region: '',
    line_type: '',
    job: '',
    avaliableLineTypes: [],
    jobOptions: [],
    regionOptions: [],
    direction: '',
    avaliableDirections: {},
});


const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS';
export const setSelectedFilters = (
    newJob='',
    newRegion='',
    newLineType='',
    newDirection='',
    newDateRange,
    newAvaliableLineTypes=List([]),
    newAvaliableDirections=Map({}),
) => ({
    type: SET_SELECTED_FILTERS,
    newJob,
    newRegion,
    newLineType,
    newDateRange,
    newAvaliableLineTypes,
    newAvaliableDirections,
    newDirection,
});

const SET_DATE_RANGE = 'SET_DATE_RANGE';
export const setDateRange = (dateRange) => ({
    type: SET_DATE_RANGE,
    dateRange,
});


const SET_ALL_JOBS = 'SET_ALL_JOBS';
export const setAllJobs = (allJobs) => ({
    type: SET_ALL_JOBS,
    allJobs,
});

const SET_ALL_REGIONS = 'SET_ALL_REGIONS';
export const setAllRegions = (allRegions) => ({
    type: SET_ALL_REGIONS,
    allRegions,
});


const retroReducer = (state = initState, action) => {
    switch(action.type){

        case SET_SELECTED_FILTERS:
            const {
                newJob,
                newRegion,
                newDateRange,
                newLineType,
                newAvaliableLineTypes,
                newAvaliableDirections,
                newDirection,
            } = action;

            return state
                .set('dateRange', newDateRange)
                .set('job', newJob)
                .set('region', newRegion)
                .set('line_type', newLineType)
                .set('avaliableLineTypes', newAvaliableLineTypes)
                .set('avaliableDirections', newAvaliableDirections)
                .set('direction', newDirection)

        case SET_DATE_RANGE:
            const {dateRange} = action;
            return state.set('dateRange', dateRange)

        case SET_ALL_REGIONS:
            const {allRegions} = action;
            return state.set('regionOptions', allRegions)

        case SET_ALL_JOBS:
            const {allJobs} = action;
            return state.set('jobOptions', allJobs)

        default:
            return state;
    }
};


export default retroReducer;
