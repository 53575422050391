import { List } from "immutable";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// import specRiteLogo from "../images/SRO_red_1.svg";
import specRiteLogo from "../images/logo-for-auth0.png";

import REPORTS_BLACK from "../images/reports-black.svg";
import SKYVIEW_BLACK from "../images/skyview-black.svg";
import TRUCK_BLACK from "../images/truck-black.svg";
import ADMIN_BLACK from "../images/admin-black.svg";
import ANALYTICS_BLACK from "../images/analytics-black.svg";
import TEMPLATES_BLACK from "../images/templates-black.svg";
import APP_SVG_BLACK from "../images/app-black.svg";
import BRIEFCASE_SVG from "../images/jobs-black.svg";
import API_SVG_BLACK from "../images/api-black.svg";
import HOME_SVG from "../images/home-black.svg";
import SCHEDULING_BLACK from "../images/scheduling-black.svg";
import OPTIONS_SVG from "../images/options.svg";

import HOME_BLUE from "../images/dashboard-blue.svg";
import SKYVIEW_BLUE from "../images/skyview-blue.svg";
import TRUCK_BLUE from "../images/truck-blue.svg";
import REPORTS_BLUE from "../images/reports-blue.svg";
import ADMIN_BLUE from "../images/admin-blue.svg";
import SCHEDULING_BLUE from "../images/scheduling-blue.svg";
import ANALYTICS_BLUE from "../images/analytics-blue.svg";
import TEMPLATES_BLUE from "../images/templates-blue.svg";
import JOBS_BLUE from "../images/jobs-blue.svg";
import APP_SVG_BLUE from "../images/app_icon.svg";
import API_SVG_BLUE from "../images/api_icon.svg";
import { 
    Drawer,
    List as MUIList,
    ListItem 
} from "@mui/material";


const sidebarIcons = {
    dashboard: {black: HOME_SVG, blue: HOME_BLUE},
    skyview: {black: SKYVIEW_BLACK, blue: SKYVIEW_BLUE},
    reports: {black: REPORTS_BLACK, blue: REPORTS_BLUE},
    equipment: {black: TRUCK_BLACK, blue: TRUCK_BLUE},
    admin: {black: ADMIN_BLACK, blue: ADMIN_BLUE},
    analytics: {black: ANALYTICS_BLACK, blue: ANALYTICS_BLUE},
    templates: {black: TEMPLATES_BLACK, blue: TEMPLATES_BLUE},
    scheduling: {black: SCHEDULING_BLACK, blue: SCHEDULING_BLUE},
    options: {black: OPTIONS_SVG, blue: OPTIONS_SVG},
    jobs: {black: BRIEFCASE_SVG, blue: JOBS_BLUE},
    app: {black: APP_SVG_BLACK, blue: APP_SVG_BLUE},
    apis: {black: API_SVG_BLACK, blue: API_SVG_BLUE},
    job_share: {black: BRIEFCASE_SVG, blue: JOBS_BLUE},

};

function SideBarContainer(props) {
    const location = useLocation();
    const currentPage = location && location.pathname.split("/")[1];

    const pages = useSelector((state) =>
        state.app.getIn(["user", "pages"], List([]))
    );
    const isDev = useSelector((state) => 
        state.app.getIn(["user", "isDev"], false)
    )
    const organizationName = useSelector((state) =>
        state.app.getIn(["organization", "name"], "noOrgID")
    );

    return (
        <Drawer variant="permanent" >
            <Box
                sx={{
                    margin: '10px auto'

                }}
            >
                <img
                    src={specRiteLogo}
                    height={44}
                    width={42}
                    alt="Spec-Rite Logo"
                />
            </Box>
            {isDev && (
                <Typography
                    sx={{
                        maxWidth: "80px",
                        textAlign: "center",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        marginLeft: "2px"
                    }}
                    variant="h6"
                >
                    {organizationName}
                </Typography>
            )}
            {pages.size ? (
                <MUIList
                    sx={{
                        marginLeft: ".25em",
                        marginRight: ".25em",
                        width: "80px",
                        height: "100%"
                    }}
                >
                    {pages.map((page, index) => {
                        let link = page.get("link", "");
                        const pageID = page.get("_id", "");

                        const blackIcon = sidebarIcons[pageID]?.black;
                        const blueIcon = sidebarIcons[pageID]?.blue;

                        return (
                            <Link
                                key={link}
                                to={link}
                                style={{
                                    position:
                                        pageID === "options"
                                            ? "absolute"
                                            : "inherit",
                                    bottom: 4,
                                    width: "100%",
                                    textDecoration: "none",
                                    "&hover": {
                                        background: "gray"
                                    }
                                }}
                            >
                                <ListItem
                                    button
                                    sx={{
                                        padding: 1,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        borderRadius: "6px",
                                        color:
                                            pageID === currentPage
                                                ? "rgb(50, 108, 133)"
                                                : "rgba(0, 0, 0, 0.6)",
                                        bgcolor:
                                            pageID === currentPage
                                                ? "rgba(50, 108, 133, 0.3)"
                                                : "background.default"
                                    }}
                                >
                                    <img
                                        alt="nav icon"
                                        src={pageID === currentPage  ? blueIcon : blackIcon}
                                        height={17}
                                    />
                                    <ListItemText
                                        disableTypography
                                        primary={page.get("label", "None")}
                                        sx={{
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            textAlign: 'center',
                                        }}
                                    />
                                </ListItem>
                            </Link>
                        );
                    })}
                </MUIList>
            ) : null}
        </Drawer>
    );
}


export default SideBarContainer;
