import Immutable, { List, Map } from 'immutable';
import { v4 as uuidv4 } from 'uuid';
import { appActionTypes } from '../../../shared/stores/app_actionTypes';
import { APPLICATION, EQUIPMENT, RETRO } from '../config_values';

// layer types
// const APPLICATION = 'application';
// const EQUIPMENT = 'equipment';

// view types
const PATTERN = 'pattern';
const PREFORMANCE = 'preformance';

// layer id
// dataRange = {dates: [], key: 'mtd'}
const NEW_LAYER_ID = null;
// const DATE_RANGE_DEFAULT = null;
const JOB_ID_DEFAULT = null;
const EQUIPMENT_ID_DEFAULT = null;
// const JOB_ID_DEFAULT = Map({});
// const EQUIPMENT_ID_DEFAULT = Map({});

const initalApplicationLayer = Immutable.fromJS({
    jobID: JOB_ID_DEFAULT,
    equipmentID: EQUIPMENT_ID_DEFAULT,
    view: PATTERN,
    // view: 'eagle_eye',
    featureCollection: {type: 'FeatureCollection', features: []},
    color: '',
    isShowingEagleEye: false,
    isShowingHandwork: false,
});

const initialState = Immutable.fromJS({
    layerType: APPLICATION,
    // layerType: RETRO,
    mileMarkersLayer: false,
    [APPLICATION]: {
        createReport: {reportID: null, layerID: null},
        isMapDataLoading: false,
        isShowingComments: false,
        selectedLayer: null,
        layers: [],
        mapStyle: 'dark-v10',
        // category: false,
    },
    [EQUIPMENT]: {
        allEquipment: [],
        selectedRow: null,
        // selectedEquipment: null,
    },
    [RETRO]: {
        job: {},
        lineType: '',
        dateFrom: '',
        dateTo: '',

    },


});
const TOGGLE_HANDWORK = 'TOGGLE_HANDWORK';
export const toggleHandwork = () => {
    return ({
        type: TOGGLE_HANDWORK,
    });
};
const TOGGLE_EAGLE_EYE = 'TOGGLE_EAGLE_EYE';
export const toggleEagleEye = () => {
    return ({
        type: TOGGLE_EAGLE_EYE,
    });
};

// retro
const SET_FILENAME = 'SET_FILENAME';
export const setFilename = (filename) => {
    return ({
        type: SET_FILENAME,
        filename
    });
};

const SET_REPORT_ID = 'SET_REPORT_ID';
export const setReportID = (reportID, exportLayerID) => {
    return ({
        type: SET_REPORT_ID,
        reportID,
        exportLayerID
    });
};

const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY';
export const setSelectedCategory = (category, cat_layerID) => {
    return ({
        type: SET_SELECTED_CATEGORY,
        category,
        cat_layerID
    });
};

const TOGGLE_MILE_MARKERS = 'TOGGLE_MILE_MARKERS';
export const toggleMileMarkers = () => {
    return ({
        type: TOGGLE_MILE_MARKERS
    });
};
const TOGGLE_COMMENTS = 'TOGGLE_COMMENTS';
export const toggleComments = () => {
    return ({
        type: TOGGLE_COMMENTS
    });
};
const UPDATE_OR_ADD_LAYER = 'UPDATE_OR_ADD_LAYER';
export const updateOrAddLayer = ({
    id=NEW_LAYER_ID,
    jobID=JOB_ID_DEFAULT,
    equipmentID=EQUIPMENT_ID_DEFAULT,
    dateRange,
    // dateRange=DATE_RANGE_DEFAULT,
    featureCollection={type: 'FeatureCollection', features: []},
    view=PATTERN // there will always be view selected
}) => {
    return {
        type: UPDATE_OR_ADD_LAYER,
        id,
        jobID,
        equipmentID,
        dateRange,
        view,
        // featureCollection,
    };
}

const SET_JOB_TABLE_SELECTION = 'SET_JOB_TABLE_SELECTION';
export const setJobTableSelection = (jobID, dateRange) => {
    return ({
        type: SET_JOB_TABLE_SELECTION,
        jobID,
        dateRange,
        id: 'JOB_PAGE',
    });
};

const CHANGE_SELECTED_LAYER = 'CHANGE_SELECTED_LAYER';
export const changeSelectedLayer = (layerID) => {
    return ({
        type: CHANGE_SELECTED_LAYER,
        layerID
    });
};

const UPDATE_MAP_STYLE = 'UPDATE_MAP_STYLE';
export const updateMapStyle = (mapStyle) => {
    return ({
        type: UPDATE_MAP_STYLE,
        mapStyle
    });
};

const DELETE_LAYER = 'DELETE_LAYER';
export const deleteLayer = (del_layerID) => {
    return ({
        type: DELETE_LAYER,
        del_layerID
    });
};

const SELECT_SEGMENT = 'SELECT_SEGMENT';
export const selectSegment = (segmentID, segmentIndex, seg_mapLayerID) => {
    return ({
        type: SELECT_SEGMENT,
        segmentID,
        segmentIndex,
        seg_mapLayerID,
    });
};

const CLEAR_SEGMENT = 'CLEAR_SEGMENT';
export const clearSegment = (clearLayerID) => {
    return ({
        type: CLEAR_SEGMENT,
        clearLayerID,
    });
};

const SET_FEATURE_COLLECTION = 'SET_FEATURE_COLLECTION';
export const setFeatureCollection = (featureCollection, fc_layerID) => {
    return ({
        type: SET_FEATURE_COLLECTION,
        featureCollection,
        fc_layerID
    });
};

const SELECT_JOB = 'SELECT_JOB';
export const selectJob = (job, job_mapLayerID) => {
    return ({
        type: SELECT_JOB,
        job,
        job_mapLayerID
    });
};

const SET_LAYER_LOADING = 'SET_LAYER_LOADING';
export const setLayerLoading = (loadinglayerID) => {
    return ({
        type: SET_LAYER_LOADING,
        loadinglayerID
    });
};

const SET_EQUIPMENT_FROM_DASHBOARD = 'SET_EQUIPMENT_FROM_DASHBOARD';
export const setEquipmentFromDashboard = (truckID) => {
    return ({
        type: SET_EQUIPMENT_FROM_DASHBOARD,
        truckID
    });
};

const SET_ALL_EQUIPMENT = 'SET_ALL_EQUIPMENT';
export const setAllEquipment = (allEquipment) => {
    return ({
        type: SET_ALL_EQUIPMENT,
        allEquipment
    });
};

const SET_SELECTED_EQUIPMENT = 'SET_SELECTED_EQUIPMENT';
// equipmentID is just its index id which will match the row index in the table
export const setSelectedEquipment = (rowID) => {
    return ({
        type: SET_SELECTED_EQUIPMENT,
        rowID
    });
};


const UPDATE_EQUIPMENT = 'UPDATE_EQUIPMENT';
export const updateEquipment = (equipmentUpdate) => {
    return ({
        type: UPDATE_EQUIPMENT,
        equipmentUpdate

    });
};

const HANDLE_DROPDOWN_SELECT = 'HANDLE_DROPDOWN_SELECT';
export const handleDropdownSelect = (dropdownSelection) => {
    return ({
        type: HANDLE_DROPDOWN_SELECT,
        dropdownSelection

    });
};

const HANDLE_MAP_LAYER_CHANGE = 'HANDLE_MAP_LAYER_CHANGE';
export const handleMapLayerChange = (layerType) => {
    return ({
        type: HANDLE_MAP_LAYER_CHANGE,
        layerType

    });
};




let layerColors = [
    '#009FD7',
    '#6c27B6',
    '#006CDB',
    '#6AB9A8',
    '#E977DD',
];

const skyviewReducer = (state=initialState, action) => {
    switch(action.type){

        case SET_FILENAME:
            const { filename } = action;
            console.log('filename', filename);
            return state.setIn([RETRO, 'filename'], filename);

        case SET_REPORT_ID:
            const { reportID, exportLayerID } = action;
            return state
                .setIn([APPLICATION, 'createReport', 'reportID'], reportID)
                .setIn([APPLICATION, 'createReport', 'layerID'], exportLayerID)

        case HANDLE_MAP_LAYER_CHANGE:
            const { layerType} = action;
            return state.set('layerType', layerType);

        case HANDLE_DROPDOWN_SELECT:
            const { dropdownSelection } = action;
            if (dropdownSelection === null) return state.setIn([EQUIPMENT, 'selectedRow'], null);
            return state.setIn([EQUIPMENT, 'selectedRow'], dropdownSelection);

        case UPDATE_EQUIPMENT:
            let equipmentUpdate = action.equipmentUpdate;

            let _allEquipment = state.getIn([EQUIPMENT, 'allEquipment'], Map({}));
            const _equipmentID  = equipmentUpdate.get('serial_number', 'not_found');

            const isExistingEquipment = _allEquipment.get(_equipmentID, false);
            if (!isExistingEquipment) return state;

            // checking for bad coords and dropping them if we find them.
            const coords = equipmentUpdate.getIn(['last_location', 'coordinates'], List([]));

            // if both coords are 0 0 then those are bad and we want to
            // delete them
            if (coords.get(0, 0) === 0 && coords.get(1, 0) === 0) {
                equipmentUpdate = equipmentUpdate.delete('last_location');
            }

            const updatedEquipment = _allEquipment.get(_equipmentID).merge(equipmentUpdate);
            _allEquipment = _allEquipment.set(_equipmentID, updatedEquipment);


            return state.setIn([EQUIPMENT, 'allEquipment'], _allEquipment);

        case SET_EQUIPMENT_FROM_DASHBOARD:
            let { truckID } = action;
            return state
                .setIn([EQUIPMENT, 'selectedRow'], truckID)
                .set('layerType', EQUIPMENT);

        case SET_SELECTED_EQUIPMENT:
            let { rowID } = action;
            const currentlySelectedRow = state.getIn([EQUIPMENT, 'selectedRow'], null);
            rowID = currentlySelectedRow === rowID ? null : rowID;
            return state.setIn([EQUIPMENT, 'selectedRow'], rowID);

        case SET_ALL_EQUIPMENT:
            const {allEquipment} = action;
            let _allEquipmentObjs = Map({});
            allEquipment.forEach(eq => {
                const eqSerialNumber = eq.get('serial_number', null);
                if (eqSerialNumber === null) return;

                _allEquipmentObjs = _allEquipmentObjs.set(eqSerialNumber, eq);
            });
            // let temp_allEquipment = allEquipment.merge(allEquipment);
            // temp_allEquipment = temp_allEquipment.merge(allEquipment);
            // temp_allEquipment = temp_allEquipment.merge(allEquipment);
            // temp_allEquipment = temp_allEquipment.merge(allEquipment);
            // temp_allEquipment = temp_allEquipment.merge(allEquipment);
            // temp_allEquipment = temp_allEquipment.merge(allEquipment);
            // temp_allEquipment = temp_allEquipment.merge(allEquipment);
            // temp_allEquipment = temp_allEquipment.merge(allEquipment);
            // temp_allEquipment = temp_allEquipment.merge(allEquipment);
            // temp_allEquipment = temp_allEquipment.merge(allEquipment);
            // temp_allEquipment = temp_allEquipment.merge(allEquipment);
            // temp_allEquipment = temp_allEquipment.merge(allEquipment);
            // temp_allEquipment = temp_allEquipment.merge(allEquipment);
            // temp_allEquipment = temp_allEquipment.merge(allEquipment);
            // temp_allEquipment = temp_allEquipment.merge(allEquipment);
            // temp_allEquipment = temp_allEquipment.merge(allEquipment);
            // temp_allEquipment = temp_allEquipment.merge(allEquipment);
            // temp_allEquipment = temp_allEquipment.merge(allEquipment);
            // temp_allEquipment = temp_allEquipment.merge(allEquipment);
            // temp_allEquipment = temp_allEquipment.merge(allEquipment);
            // temp_allEquipment = temp_allEquipment.merge(allEquipment);
            // temp_allEquipment = temp_allEquipment.merge(allEquipment);
            // temp_allEquipment = temp_allEquipment.merge(allEquipment);
            // temp_allEquipment = temp_allEquipment.merge(allEquipment);
            // temp_allEquipment = temp_allEquipment.merge(allEquipment);
            // temp_allEquipment = temp_allEquipment.merge(allEquipment);
            // temp_allEquipment = temp_allEquipment.merge(allEquipment);
            return state.setIn([EQUIPMENT, 'allEquipment'], _allEquipmentObjs);
            // return state.setIn([EQUIPMENT, 'allEquipment'], temp_allEquipment);

        case SET_LAYER_LOADING:
            const {loadinglayerID} = action;
            return state
                .setIn([APPLICATION, 'layers', loadinglayerID, 'isMapDataLoading'], true)
                .setIn([APPLICATION, 'layers', loadinglayerID, 'featureCollection'], {type: 'FeatureCollection', features: []})


        case SET_FEATURE_COLLECTION:
            const { featureCollection, fc_layerID } = action;
            return state
                .setIn([APPLICATION, 'layers', fc_layerID, 'featureCollection'], featureCollection)
                .setIn([APPLICATION, 'layers', fc_layerID, 'isMapDataLoading'], false);

        case SELECT_JOB:
            const { job, job_mapLayerID} = action;

            const job_layerID = state
                .getIn([APPLICATION, 'layers'], List([]))
                .findIndex(_layer => {
                    return job_mapLayerID === _layer.get('mapLayerID', '');
                });

            if (job_layerID === -1) return state;
            return state
                .setIn([APPLICATION, 'layers', job_layerID, 'jobID'], job)
                .setIn([APPLICATION, 'layers', job_layerID, 'isMapDataLoading'], true)
                .setIn([APPLICATION, 'selectedLayer'], job_layerID)
                .setIn([APPLICATION, 'layers', job_layerID, 'featureCollection'], {type: 'FeatureCollection', features: []})

        case CLEAR_SEGMENT:
            const clearLayerID = state.getIn([APPLICATION, 'selectedLayer'], '');
            return state
                .deleteIn([APPLICATION, 'layers', clearLayerID, 'segmentID'])
                .deleteIn([APPLICATION, 'layers', clearLayerID, 'segmentIndex'])

        case appActionTypes["app/changeUserOrg"]:
            const layers = state.getIn([APPLICATION, 'layers'], List([]));
            if (layers.size === 0) return state;

            // remove all the layer colors
            layers.forEach(layer => {
                const color = layer.get('color');
                layerColors.unshift(color);
            });

            return state.setIn([APPLICATION, 'layers'], List([]));

        case SET_SELECTED_CATEGORY:
            const {category, cat_layerID} = action;
            const cat_layer = state.setIn([APPLICATION, 'layers'], null);
            if (cat_layer === null) return state;
            return state.setIn([APPLICATION, 'layers', cat_layerID, 'category'], category);

        case TOGGLE_HANDWORK:
            const handworkLayer = state.getIn([APPLICATION, 'selectedLayer'], '');
            const _isShowingHandwork = state.getIn([APPLICATION, 'layers', handworkLayer, 'isShowingHandwork'], false);
            return state.setIn([APPLICATION, 'layers', handworkLayer, 'isShowingHandwork'], !_isShowingHandwork);

        case TOGGLE_EAGLE_EYE:
            const eagleEyeLayer = state.getIn([APPLICATION, 'selectedLayer'], '');
            const _isShowingEagleEye = state.getIn([APPLICATION, 'layers', eagleEyeLayer, 'isShowingEagleEye'], false);
            return state.setIn([APPLICATION, 'layers', eagleEyeLayer, 'isShowingEagleEye'], !_isShowingEagleEye);

        case TOGGLE_COMMENTS:
            const newCommentsLayer = state.get('isShowingComments', false);
            return state.set('isShowingComments', !newCommentsLayer);

        case TOGGLE_MILE_MARKERS:
            const newMileMarkerLayer = state.get('mileMarkersLayer', false);
            return state.set('mileMarkersLayer', !newMileMarkerLayer);

        case UPDATE_MAP_STYLE:
            const mapStyle = action.mapStyle;
            return state.setIn([APPLICATION, 'mapStyle'], mapStyle);

        case SELECT_SEGMENT: 
            const {
                segmentID,
                segmentIndex,
                seg_mapLayerID
            } = action;
            const seg_layerID = state
                .getIn([APPLICATION, 'layers'], List([]))
                .findIndex(_layer => {
                    return seg_mapLayerID === _layer.get('mapLayerID', '');
                });

            if (seg_layerID === -1) return state;

            let seg_newState = state
                .setIn([APPLICATION, 'layers', seg_layerID, 'segmentID'], segmentID)
                .setIn([APPLICATION, 'layers', seg_layerID, 'segmentIndex'], segmentIndex)

            const currentlySelectedLayer = state.getIn([APPLICATION, 'selectedLayer'], null);
            if (seg_layerID !== currentlySelectedLayer){
                seg_newState = seg_newState.setIn([APPLICATION, 'selectedLayer'], seg_layerID);
            }

            return state.merge(seg_newState);


        case SET_JOB_TABLE_SELECTION:
        case UPDATE_OR_ADD_LAYER: 

            let { 
                id,
                jobID,
                equipmentID=EQUIPMENT_ID_DEFAULT,
                view=PATTERN,
                dateRange,
                // featureCollection,
            } = action;

            const currentAmountOfLayers = state.getIn([APPLICATION, 'layers'], List([])).size
            if (id === 'JOB_PAGE' && currentAmountOfLayers === 5){
                id = 4;
            };


            let updatedLayer = id !== null && id !== 'JOB_PAGE'
                ? state.getIn([APPLICATION, 'layers', id], initalApplicationLayer)
                : initalApplicationLayer;

            if(id !== null){
                updatedLayer = updatedLayer.set('dateRange', dateRange);
                updatedLayer = updatedLayer.set('jobID', jobID);
                updatedLayer = updatedLayer.set('equipmentID', equipmentID);
                updatedLayer = updatedLayer.set('view', view);
                updatedLayer = updatedLayer.delete('segment');
                const eagleEye = equipmentID === null 
                    ? false
                    :  equipmentID.get('eagle_eye', false);
                const isEagleEyeCurrentlySelected = updatedLayer.get('isShowingEagleEye', false);
                if (isEagleEyeCurrentlySelected && !eagleEye){
                    updatedLayer = updatedLayer.set('isShowingEagleEye', false);
                }
                

            }

            // here we either use the given id, or we create a new one (which is
            // just the layer index in the array) from the current length of the
            // layers array
            let _layerID = id;
            if (_layerID === null || _layerID === 'first' || _layerID === 'JOB_PAGE') {
                _layerID = state.getIn([APPLICATION, 'layers'], List([])).size
                const maplayerID = uuidv4();
                const newLayerColor = layerColors.shift();
                updatedLayer = updatedLayer.set('mapLayerID', maplayerID);
                updatedLayer = updatedLayer.set('color', newLayerColor);

            }
            return state
                .setIn([APPLICATION, 'layers', _layerID], updatedLayer)
                .setIn([APPLICATION, 'selectedLayer'], _layerID)
                .set('layerType', APPLICATION)

        case CHANGE_SELECTED_LAYER:
            const { layerID } = action;
            return state.setIn([APPLICATION, 'selectedLayer'], layerID);

        case DELETE_LAYER:
            const {del_layerID } = action;
            const del_layerColor = state.getIn([APPLICATION, 'layers', del_layerID, 'color'], '');
            layerColors.unshift(del_layerColor);
            let del_state = state.deleteIn([APPLICATION, 'layers', del_layerID]);

            const del_selectedLayer = state.getIn([APPLICATION, 'selectedLayer'], null);
            const amountOfLayers = del_state
                .getIn([APPLICATION, 'layers'], List([])).size;
            // .filter(layer => layer !== null);

            let newSelectedLayer = del_layerID !== del_selectedLayer && del_layerID > del_selectedLayer
                ? del_selectedLayer 
                : del_layerID !== del_selectedLayer && del_layerID < del_selectedLayer
                ? del_selectedLayer -1
                : amountOfLayers > 0
                ? 0
                : null


            del_state = del_state.setIn([APPLICATION, 'selectedLayer'], newSelectedLayer)

            return state.merge(del_state);

        default:
            return state;
    }
};


export default skyviewReducer;
