import Immutable, { List, isImmutable, Map } from 'immutable';
import { OrgDateTime } from '../../Root';

const mergeDeep = (orginalObj, newObj, isUpdate=true) => {
    let mergedObj = orginalObj.map((value, key) => {
        if (Map.isMap(value)) {
            const _newObj = newObj.get(key, Map({}));
            return mergeDeep(value, _newObj);
        };

        const newValue = newObj.get(key, '');
        if (!newValue) {
            return value;
        }

        return newValue;
    });

    const newID = newObj.get('id', '');
    if (newID && isUpdate) {
        mergedObj = mergedObj.set('id', newID);
    }

    return mergedObj
};

const BLUE = '#1B3D9F';
const GREEN = '#037C3B';
const RED = '#CF2031';
const ORANGE = '#FDA92B';
const PURPLE = '#6B3EEC';
const CLAY = '#ED6A5E';
const YELLOW = '#F5ED28';

const COLOR_OPTIONS = [
    BLUE,
    GREEN,
    RED,
    ORANGE,
    PURPLE,
    CLAY,
    YELLOW,
];


const SET_REPORT_NAME = 'SET_REPORT_NAME';
const SET_REPORT_TYPE = 'SET_REPORT_TYPE';
const SET_TEMPLATE_OPTIONS = 'SET_TEMPLATE_OPTIONS';
const SET_SELECTED_TEMPLATE = 'SET_SELECTED_TEMPLATE';
const SET_START_DATE = 'SET_START_DATE';
const SET_END_DATE = 'SET_END_DATE';
const SET_SELECTED_EQUIPMENT = 'SET_SELECTED_EQUIPMENT';
const SET_SELECTED_JOBS = 'SET_SELECTED_JOBS';
const REMOVE_JOB = 'REMOVE_JOB';
const SET_COLOR = 'SET_COLOR';
const SET_FREQUENCY = 'SET_FREQUENCY';
const SET_WHEN_TO_DELIVER = 'SET_WHEN_TO_DELIVER';
const SET_EMAIL = 'SET_EMAIL';
const SET_INCLUDE_AS = 'SET_INCLUDE_AS';
const SET_ATTACHMENT_TYPES = 'SET_ATTACHMENT_TYPES';
const SET_RUN_TIME = 'SET_RUN_TIME';
const SET_RECURRING_START_DATE = 'SET_RECURRING_START_DATE';
const SET_DAY_OF_WEEK = 'SET_DAY_OF_WEEK';
const SET_DAILY_DAY_OF_WEEK = 'SET_DAILY_DAY_OF_WEEK';
const SET_END_ON = 'SET_END_ON';
const SET_END_ON_DATE = 'SET_END_ON_DATE';
const SET_AMOUNT_OF_OCCURRANCES = 'SET_AMOUNT_OF_OCCURRANCES';
const SET_DO_NOT_SEND_EMPTY = 'SET_DO_NOT_SEND_EMPTY';
const CLEAR_STATE = 'CLEAR_STATE';
const SET_TEMPLATE_OBJ = 'SET_TEMPLATE_OBJ';
const SET_EVENT_DATA_FOR_EDITING = 'SET_EVENT_DATA_FOR_EDITING';
const OPEN_MODAL = 'OPEN_MODAL';
const SET_SKYVIEW_DATA = 'SET_SKYVIEW_DATA';
const DUPLICATE_SCHEDULED_EVENT = 'DUPLICATE_SCHEDULED_EVENT';
const UPDATE_CUSTOM_AMOUNT_AND_UNIT = 'UPDATE_CUSTOM_AMOUNT_AND_UNIT';


export const updateCustomAmountAndUnit = (keyName, amount, unit) => ({
    type: UPDATE_CUSTOM_AMOUNT_AND_UNIT,
    keyName,
    amount,
    unit
})

export const duplicateScheduledEvent = (duplicateEvent) => ({
    type: DUPLICATE_SCHEDULED_EVENT,
    duplicateEvent
});

export const updateName = (name) => ({
    type: SET_REPORT_NAME,
    name
});

export const updateType = (reportType) => ({
    type: SET_REPORT_TYPE,
    reportType
});

export const setTemplateOptions = (templateOptions) => ({
    type: SET_TEMPLATE_OPTIONS,
    templateOptions
});

export const setSelectedTemplate = (template, _reportType=null) => ({
    type: SET_SELECTED_TEMPLATE,
    template,
    _reportType
});

export const setStartDate = (startDate) => ({
    type: SET_START_DATE,
    startDate,
});

export const setEndDate = (endDate) => ({
    type: SET_END_DATE,
    endDate,
});

export const setSelectedEquipment = (equipment, isAllEquipmentSelected) => ({
    type: SET_SELECTED_EQUIPMENT,
    equipment,
    isAllEquipmentSelected
});

export const setSelectedJobs = (jobs, isAllJobs=false) => ({
    type: SET_SELECTED_JOBS,
    jobs,
    isAllJobs,
});

export const removeJob = (job) => ({
    type: REMOVE_JOB,
    job,
});

export const setColor = (color) => ({
    type: SET_COLOR,
    color,
});

export const setFrequency = (frequency) => ({
    type: SET_FREQUENCY,
    frequency,
});

export const setWhenToDeliver = (whenToDeliver) => ({
    type: SET_WHEN_TO_DELIVER,
    whenToDeliver,
});

export const setEmail = (email) => ({
    type: SET_EMAIL,
    email
});

export const setIncludeAs = (includeAs) => ({
    type: SET_INCLUDE_AS,
    includeAs
});

export const setAttachmentTypes = (attachmentType) => ({
    type: SET_ATTACHMENT_TYPES,
    attachmentType
});

export const setRunTime = (runTime) => ({
    type: SET_RUN_TIME,
    runTime
});

export const setRecurringStartDate = (r_startDate) => ({
    type: SET_RECURRING_START_DATE,
    r_startDate
});

export const setDaysOfWeek = (dayOfWeek) => ({
    type: SET_DAY_OF_WEEK,
    dayOfWeek
});

export const setDailyDaysOfWeek = (dailyDayOfWeek) => ({
    type: SET_DAILY_DAY_OF_WEEK,
    dailyDayOfWeek
});

export const setEndOn = (endOn) => ({
    type: SET_END_ON,
    endOn
});

export const setEndOnDate = (endOnDate) => ({
    type: SET_END_ON_DATE,
    endOnDate
});

export const setAmountOfOccurrences = (amountOfOccurrences) => ({
    type: SET_AMOUNT_OF_OCCURRANCES,
    amountOfOccurrences
});

export const setDoNotSendEmpty = (isChecked) => ({
    type: SET_DO_NOT_SEND_EMPTY,
    isChecked
});

export const setTemplateObj = (templateObj) => ({
    type: SET_TEMPLATE_OBJ,
    templateObj
});

export const clearState = () => ({
    type: CLEAR_STATE,
});


export const setEventData = (scheduledEvent) => ({
    type: SET_EVENT_DATA_FOR_EDITING,
    scheduledEvent

});

export const toggleModal = (isOpen) => ({
    type: OPEN_MODAL,
    isOpen
});


export const setValuesFromSkyview = (
    jobName,
    equipmentName,
    s_startDate,
    s_endDate,
    _templateObj,
    _templateID,
    _templates
) => ({
    type: SET_SKYVIEW_DATA,
    jobName,
    equipmentName,
    s_startDate,
    s_endDate,
    _templateObj,
    _templateID,
    _templates


});

const date = new Date();
const todaysDate = date.toISOString().split('T')[0]

let tomorrow = new Date(date);
tomorrow.setDate(tomorrow.getDate() + 1)
if (tomorrow.getDay() === 6 || tomorrow.getDay() === 0){
    tomorrow.setDate(tomorrow.getDate() + 2)
}
let tomorrowsDate = tomorrow.toISOString().split('T')[0]


let endDate = new Date(date);
endDate.setDate(endDate.getDate() + 2)
if (endDate.getDay() === 6 || endDate.getDay() === 0){
    endDate.setDate(endDate.getDate() + 3)
};
endDate = endDate.toISOString().split('T')[0]

const dayOfWeekValue = {
    "SU": 1,
    "M": 2,
    "TU": 3,
    "W": 4,
    "TH": 5,
    "F": 6,
    "SA": 7,
};

const isDateBefore = (_date) => {
    let newDate = _date;
    const ogStartDate = new Date(newDate);
    const today = new Date(todaysDate);
    if (ogStartDate < today) {
        newDate= todaysDate;
    }
    return newDate;

};

const initialState = Immutable.fromJS({
    isModalOpen: false,
    isEditing: false,
    name: '',
    type: 'one_time',
    templateOptions: [],
    selectedTemplate: '',
    // templateObj: {},
    startDate: '',
    endDate: '',
    equipment: [],
    isAllEquipmentSelected: false,
    jobs: [],
    isAllJobs: false,
    color: BLUE,
    orgID: '',
    do_not_send_empty: false,
    includeMap: false,
    deliveryOptions: {
        deliveryMethods: ["email"],
        whenToDeliver: "immediately",
        emails: '',
        phoneNumber: [],
        attachmentTypes: ["pdf"],
        // includeAs
        includeAs: ["link"],
        sftpUsername: '',
        sftpIPAddress: '',
        sftpPassword: '',
        custom_whenToDeliver: {
            amount: 10,
            unit: 'minutes',
        }
    },
    dateTime: {
        frequency: "daily",
        runTime: '06:00',
        endOn: {
            value: "never",
            endOnDate: endDate,
            amountOfOccurrences: 5
        },
        weekly_daysOfWeek: ['M'],
        daily_daysOfWeek: "workWeek",
        startDate: tomorrowsDate
    }
});


const newReportReducer = (state=initialState, action) => {
    switch(action.type){

        case UPDATE_CUSTOM_AMOUNT_AND_UNIT: 
            const {keyName, amount, unit} = action;
            if (keyName === 'amount') {
                return state.setIn(
                ['deliveryOptions', 'custom_whenToDeliver', 'amount'],
                    parseInt(amount));
            }
            if (keyName === 'unit') {
                return state.setIn(['deliveryOptions', 'custom_whenToDeliver', 'unit'], unit);
            }
            return state;

        case DUPLICATE_SCHEDULED_EVENT: 
            let duplicateEvent = action.duplicateEvent;
            let eventName = duplicateEvent.get('name');
            eventName = `DUPLICATE-${eventName}`;
            duplicateEvent = duplicateEvent.delete('id');
            duplicateEvent = duplicateEvent.set('name', eventName);

            const __runTime = duplicateEvent
                .getIn(['dateTime', 'runTime'], '')
                .split(' ')[0]
            duplicateEvent = duplicateEvent.setIn(['dateTime', 'runTime'], __runTime);

            let _sDate = duplicateEvent.getIn(['dateTime', 'startDate'], '');
            if (_sDate) {
                _sDate = isDateBefore(_sDate);
            }
            let _eDate = duplicateEvent.getIn(['dateTime', 'endOn', 'endOnDate'], '');
            if (_eDate) {
                _eDate = isDateBefore(_eDate);
            }

            duplicateEvent = duplicateEvent
                .setIn(['dateTime', 'startDate'], _sDate)
                .setIn(['dateTime', 'endOn', 'endOnDate'], _eDate);

            // turn the email array in a string
            const _emails = duplicateEvent
                .getIn(['deliveryOptions', 'emails'], List([]))
                .join(', ');
            duplicateEvent = duplicateEvent.setIn(['deliveryOptions', 'emails'], _emails);

            const __templateOptions = state.get('templateOptions', List([]));
            const __templateID = duplicateEvent.get('templateID', List([]));
            const duplicatedState = mergeDeep(initialState, duplicateEvent, false); 
            return duplicatedState
                .set('isModalOpen', true)
                .set('type', 'recurring')
                .set('templateOptions', __templateOptions)
                .set('selectedTemplate', __templateID);

        case SET_SKYVIEW_DATA:
            const {
                jobName,
                equipmentName,
                s_startDate,
                s_endDate,
                _templateObj,
                _templateID,
                _templates
            } = action;

            return state
                .set('jobs', jobName)
                .set('equipment', equipmentName)
                .set('startDate', s_startDate)
                .set('endDate', s_endDate)
                .set('selectedTemplate', _templateID)
                .set('templateObj', _templateObj)
                .set('isModalOpen', true)
                .set('templateOptions', _templates)


        case OPEN_MODAL:
            const { isOpen } = action;
            return state.set('isModalOpen', isOpen);

        case SET_EVENT_DATA_FOR_EDITING:
            let { scheduledEvent } = action;
            scheduledEvent = scheduledEvent.setIn(
                ['dateTime'],
                scheduledEvent.get('dateTime')
            );
            scheduledEvent = scheduledEvent.setIn(
                ['deliveryOptions'],
                scheduledEvent.get('deliveryOptions')
            );
            scheduledEvent = scheduledEvent.setIn(
                ['color'],
                scheduledEvent.get('color')
            );
            const _runTime = scheduledEvent
                .getIn(['dateTime', 'runTime'], '')
                .split(' ')[0]
            scheduledEvent = scheduledEvent.setIn(
                ['dateTime', 'runTime'], 
                _runTime
            );

            // turn the email array in a string
            const emails = scheduledEvent
                .getIn(['deliveryOptions', 'emails'], List([]))
                .join(', ');
            scheduledEvent = scheduledEvent.setIn(
                ['deliveryOptions', 'emails'],
                emails
            );

            // const initReportObject = ini
            let updatedAutoReport = mergeDeep(initialState, scheduledEvent);
            updatedAutoReport = updatedAutoReport.set(
                'name', scheduledEvent.get('name', List([]))
            );
            updatedAutoReport = updatedAutoReport.set(
                'jobs', scheduledEvent.get('jobs', List([]))
            );
            updatedAutoReport = updatedAutoReport.set(
                'equipment', scheduledEvent.get('equipment', List([]))
            );
            updatedAutoReport = updatedAutoReport.set(
                'templateOptions',
                state.get('templateOptions', List([]))
            );
            updatedAutoReport = updatedAutoReport.set(
                'selectedTemplate', scheduledEvent.get('templateID', '')
            );
            return updatedAutoReport
                .set('isModalOpen', true)
                .set('type', 'recurring')
                .set('isEditing', true);

        // case SET_TEMPLATE_OBJ:
        //     const {templateObj} =  action;
        //     return state.set('templateObj', templateObj);

        case CLEAR_STATE:
            const _templateOptions = state.get('templateOptions', List([]));
            return initialState.set('templateOptions', _templateOptions);

        case SET_DO_NOT_SEND_EMPTY:
            const { isChecked } = action;
            return state.setIn(
                ['do_not_send_empty'],
                isChecked
            );

        case SET_AMOUNT_OF_OCCURRANCES:
            const { amountOfOccurrences } = action;
            return state.setIn(
                [ 'dateTime', 'endOn', 'amountOfOccurrences'],
                amountOfOccurrences
            );

        case SET_END_ON_DATE:
            const { endOnDate } = action;
            return state.setIn(
                [ 'dateTime', 'endOn', 'endOnDate'], endOnDate
            );

        case SET_END_ON:
            const { endOn } = action;
            return state.setIn(
                [ 'dateTime', 'endOn', 'value'], endOn
            );

        case SET_DAILY_DAY_OF_WEEK:
            const {dailyDayOfWeek} = action;
            return state.setIn(
                [ 'dateTime', 'daily_daysOfWeek'], dailyDayOfWeek
            );

        case SET_DAY_OF_WEEK:
            const _newDaySelect = action.dayOfWeek
            let weekly_daysOfWeek = state.getIn(
                [ 'dateTime', 'weekly_daysOfWeek'],
                List([])
            );
            const indexOfSelectedDay = weekly_daysOfWeek.indexOf(_newDaySelect);

            if (indexOfSelectedDay !== -1){
                weekly_daysOfWeek = weekly_daysOfWeek.delete(indexOfSelectedDay);
            } else {
                weekly_daysOfWeek = weekly_daysOfWeek.push(_newDaySelect);
            };

            weekly_daysOfWeek = weekly_daysOfWeek.sort((a, b) => {
                if (dayOfWeekValue[a] < dayOfWeekValue[b]) {
                    return -1;
                }
                return 1
            });

            return state.setIn(
                [ 'dateTime', 'weekly_daysOfWeek'],
                weekly_daysOfWeek
            );


        case SET_RECURRING_START_DATE:
            const { r_startDate } = action;
            return state.setIn(
                [ 'dateTime', 'startDate'], r_startDate
            );

        case SET_RUN_TIME:
            const { runTime } = action;
            return state.setIn(
                [ 'dateTime', 'runTime'], runTime
            );

        case SET_ATTACHMENT_TYPES:
            const {attachmentType} = action;

            const currentSelectedAttachmentTypes = state.getIn(
                [ 'deliveryOptions', 'attachmentTypes'], 
                List([])
            );
            let newSelectedAttachmentTypes = currentSelectedAttachmentTypes;

            const indexOfSelectedAttachment = currentSelectedAttachmentTypes
                .indexOf(attachmentType); 

            if(indexOfSelectedAttachment === -1){
                newSelectedAttachmentTypes = newSelectedAttachmentTypes
                    .push(attachmentType);
            } else {
                newSelectedAttachmentTypes = newSelectedAttachmentTypes
                    .delete(indexOfSelectedAttachment);
            }

            return state.setIn(
                [ 'deliveryOptions', 'attachmentTypes'],
                newSelectedAttachmentTypes
            );

        case SET_INCLUDE_AS:
            const { includeAs } = action;
            let newFormats = state.getIn(
                [ 'deliveryOptions', 'includeAs'], List([])
            );
            const indexOfFormat = newFormats.indexOf(includeAs);
            if (indexOfFormat === -1){
                newFormats = newFormats.push(includeAs);
            } else {
                newFormats = newFormats.delete(indexOfFormat);
            }
            return state.setIn(
                [ 'deliveryOptions', 'includeAs'],
                newFormats
            );

        case SET_EMAIL:
            const { email } = action;
            return state.setIn([ 'deliveryOptions', 'emails'], email);

        case SET_WHEN_TO_DELIVER:
            const { whenToDeliver } = action;
            return state.setIn(
                [ 'deliveryOptions', 'whenToDeliver'], whenToDeliver
            );


        case SET_FREQUENCY:
            const { frequency } = action;
            return state.setIn([ 'dateTime', 'frequency'], frequency);

        case SET_COLOR:
            const { color } = action;
            return state.setIn([ 'color'], color);


        case REMOVE_JOB:
            const { job } = action;
            let _jobs = state.get('jobs', List([]));
            const jobIndex = _jobs.indexOf(job);
            if (jobIndex === -1) return state;
            _jobs = _jobs.delete(jobIndex);
            return state.set('jobs', _jobs);


        case SET_SELECTED_JOBS:
            let {jobs, isAllJobs} = action;

            if (!isImmutable(jobs)){
                jobs = Immutable.fromJS(jobs);
            }
            return state
                .set('jobs', jobs)
                .set('isAllJobs', isAllJobs);

        case SET_SELECTED_EQUIPMENT:
            let {equipment, isAllEquipmentSelected} = action;
            if (!isImmutable(equipment)){
                equipment = Immutable.fromJS(equipment);
            }
            return state
                .set('equipment', equipment)
                .set('isAllEquipmentSelected', isAllEquipmentSelected)

        case SET_SELECTED_TEMPLATE:
            let {template, _reportType=null} = action;
            const startDateCheck = state.get('startDate', '');
            const endDateCheck = state.get('endDate', '');
            _reportType = _reportType === null ? state.get('type', '') : _reportType;
            

            if (!startDateCheck || !endDateCheck){

                const [_date_from, _date_to] = OrgDateTime.getDatesFromRange(120, [], false, true);
                // const initStartDate = _date_from.toISO().split('T')[0];
                // const initEndDate = _date_to.toISO().split('T')[0];

                return state
                    .set('startDate', _date_from)
                    .set('endDate', _date_to)
                    .set('selectedTemplate', template)
                    .set('type', _reportType)
                    .set('isModalOpen', true);
            }

            return state
                .set('selectedTemplate', template)
                .set('type', _reportType)
                .set('isModalOpen', true);

        case SET_TEMPLATE_OPTIONS:
            const {templateOptions} = action;
            return state.set('templateOptions', templateOptions);

        case SET_REPORT_NAME:
            const { name } = action;
            return state.set('name', name);

        case SET_REPORT_TYPE:
            const { reportType } = action;
            return state.set('type', reportType);

        case SET_START_DATE:
            const { startDate } = action;
            const _endDate = state.get('endDate', '');
            let _state = state.set('startDate', startDate);
            if (startDate > _endDate) {
                _state = _state.set('endDate', startDate);
            }
            return _state;

        case SET_END_DATE:
            const { endDate } = action;
            return state.set('endDate', endDate);

        default: 
            return state
    }
};


export default newReportReducer;


