import axios from 'axios';
import Immutable from 'immutable';
import { reportAPIErrorToSentry, catchBlockErrors } from './utils/sentry';
import { showSnackbar } from './stores/app_s';

const baseURL = process.env.REACT_APP_SPEC_RITE_URL || "";

function API(dispatch){
    this.dispatch = dispatch;
    this.pendingCalls = Immutable.List([]);
    this.runningRepeat = false;
    this.endpoints = {
        // Organizations
        "OR/get_organizations": "get_organizations",
        "OR/org_qa_thresholds": "org_qa_thresholds",
        "OR/activeThresholdValue": "org_qa_thresholds/update/active",
        "OR/updateThresholdValue": "org_qa_thresholds/update/value",
        "OR/timezone": "timezone",
        "OR/updateTimezone": "/org/update/timezone",
        "OR/updateDisplayName": "/org/update/display_name",
        "OR/unitOptions": "/get_unit_options",
        "OR/updateUnitType": "/update_unit_value",
        // User and Admin
        "UA/user_info": "get_user_info",
        "UA/getUsers": "users",
        "UA/invite_user": "invite_user",
        "UA/reinvite_user": "reinvite_user",
        "UA/changeOrganization": "change_own_organization",
        create_org: "create_org",
        get_equipment_ids: "get_equipment_ids",
        get_org_locations: "get_org_locations",
        migrate_equipment: "migrate_equipment",
        add_equipment: "add_equipment",
        // Feature Related
        "FE/image_upload": "feature_image_upload",
        // Visual Assessment
        "VA/create": "visual_assessment/create",
        "VA/info": "visual_assessment_info",
        "VA/past": "visual_assessments",
        "VA/update": "visual_assessment/update_value",
        // 'VA/review': 'visual_assessment/update/reviewed',
        // Skipline
        bboxMileMarkers: "bounded_milemarkers",
        "SL/availableCategories": "available_categories",
        "SL/categoryProperties": "category_properties",
        "SL/comments": "comments",
        "SL/DBSummary": "dashboard_summary",
        "SL/equipment": "equipment",
        "SL/equipmentNames": "equipment_available",
        "SL/equipmentUpdateWaze": "equipment/update/share_location",
        "SL/get_layer_types": "layer_types",
        "SL/submission_recipient_orgs": "submission_recipient_orgs",
        "SL/get_segmentation_types": "segmentation_types",
        "SL/reportNames": "get_submitted_report_layer_names",
        "SL/reportMapData": "report_map",
        "SL/reportTableData": "report_table",
        "SL/report_submission_history": "report_submission_history",
        "SL/submit_report": "submit_report",
        "SL/submittedLayerAvailableCategories":
        "get_submitted_report_available_categories",
        "SL/submittedLayerCategoryProperties":
        "get_submitted_report_category_properties",
        "SL/submittedLayerGeoJson": "get_submitted_report_layer_geojson",
        "SL/sourceOrganizations":
        "get_submitted_report_layer_source_organizations",
        "SL/jobs": "jobs",
        "SL/jobMetaData": "job_metadata",
        "SL/mileMarkers": "milemarkers",
        "SL/orgInfo": "org_info",
        "SL/report": "report",
        "SL/report_history": "report_history",
        "SL/summary": "summary",
        "SL/templates": "templates",
        "SL/template": "template",
        "SL/truckTypes": "truck_types",
        "SL/userInfo": "user_info",
        "SL/userUpdate": "user/update",
        "SL/yearlySummary": "yearly",
        "IC/allContacts": "all_intercom_contacts",
        retroFileUpload: "upload_retroreflectivity_file",
        "SL/updateEquipmentValue": "equipment/update",

        // retroJobs: "retroreflectivity_jobs",
        uploadRetroFile: 'upload_retro',
        retroMapData: 'retro_summaries',

        retroGEOJSON: "retroreflectivity_summary",

        segmentationTypes: "segmentation_types",
        "retro/equipment": "retroreflectivity_equipment",
        "retro/categories": "available_retroreflectivity_categories",
        "retro/properties": "retroreflectivity_category_properties",

        create_or_edit_template: "custom_template",
        all_templates: "templates",
        // 'all_templates': 'custom_templates',
        get_template: "custom_template",
        all_jobs: "all_jobs",

        uploadRetroFiles: 'upload_retro',
        retroFilenames: 'retro_filenames',
        'eagleeye_data': 'eagleeye_data',
        mergeJobs: "jobs/merge",
        jobDetails: 'job_detail',
        unmerge: 'jobs/unmerge',
        updateJobName: 'jobs/rename',
        mergeImpact: 'merge_impact',
        truckSearch: 'equipment_lookup',
        sharedOrgs: 'shared_orgs',
        shareJob: 'share_job',
        'retroRegions': 'retro_regions',
        'retroJobs': 'retro_jobs',
        'retroSummaries': 'retro_summaries',
        'retro_point': 'retro_point',
        'retroData': 'retro_data_window',
        'allSharedJobs': 'all_shared_jobs',
        'allSharedReport': 'shared_report_pagination',
        'createSharedReport': 'shared_report',
        'createJob': 'add_scheduled_job',
        'allScheduledJobs': 'all_scheduled_jobs',
        'deleteJob': 'delete_scheduled_job',
        'editScheduledJob': 'jobs/reschedule',
        'unshareJob': 'unshare_job',
        'toggleJobSharing': 'toggle_job_sharing',
        'allRetroJobs': 'retro_all_jobs',
        retroUpload: 'retro_upload',
        'retroFlag': 'set_org_retro',

        'handwork': 'handwork',
        'handworkProperties': 'handwork_properties',

        'templateShareOrgs': 'template_sharing_orgs',
        'shareTemplate': 'set_template_shared',
        'deleteRetroJob': 'delete_retro',
        'sharedEquipment': 'shared_equipment',
    };

};

API.prototype.setToken = function(token){ return this.token = token };

// main method for fetch data from an api that takes a config for axios
API.prototype.call = async function({endpoint, method, options}){
    // call is for basic calls that don't need to update data in redux
    try {
        const config = this._setConfig({endpoint, method, options});
        return await this._axios({config});
    } catch(error){
        const message = "Error caught in API_m/call";
        catchBlockErrors({error, message});
    };
};

// Subscribe works almost the same as call except it takes a callbackAction to
// update the data in the redux store. 
// the response from the backend should be setup so we can dispatch it as an
// action creator
API.prototype.subscribe = async function(callConfig, callbackAction){
    try {
        const config = this._setConfig(callConfig);
        return await this._axios({config, callbackAction});
    } catch(error){
        const message = "Error caught in API_m/subscribe";
        catchBlockErrors({error, message});
    }

}

API.prototype.filesUpload = async function(
    { endpoint, options={}, data={}, callbackAction=null }
){
    try{
        const apiMethod = typeof callbackAction === 'function' ? 'subscribe' : 'call';
        const filesToUpload = options.files;

        options.data = new FormData();
        filesToUpload.forEach(file => {
            options.data.append('files', file);
        })
        options.headers = {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.token}`,
            }
        delete options.files;

        return await this[apiMethod](
            {endpoint, method: 'post', options, callbackAction}
        );
    } catch(error){
        const message = 'Error caught in API_m/fileUpload'
        catchBlockErrors({error, message});
    }
};

API.prototype.fileUpload = async function(
    { endpoint, options={}, data={}, callbackAction=null }
){
    try{
        const apiMethod = typeof callbackAction === 'function' ? 'subscribe' : 'call';
        const fileToUpload = options.file;

        options.data = new FormData();
        options.data.append('file', fileToUpload);
        options.headers = {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.token}`,
            }
        delete options.file;

        return await this[apiMethod](
            {endpoint, method: 'post', options, callbackAction}
        );
    } catch(error){
        const message = 'Error caught in API_m/fileUpload'
        catchBlockErrors({error, message});
    }
};

// For uploading images va FormData
API.prototype.photoUpload = async function(
    {endpoint, options={}, data, callbackAction=null}
){
    try{
        const apiMethod = typeof callbackAction === 'function' ? 'subscribe' : 'call';
        options.data = new FormData();
        data.forEach(d => options.data.append(d.key, d.value))
        return await this[apiMethod](
            {endpoint, method: 'post', options, callbackAction}
        );
    } catch(error){
        const message = 'Error caught in API_m/photoUpload'
        catchBlockErrors({error, message});
    }
};

// For downloading files and getting the headers of the response back in
// addition to the data. the headers are usually where we will find the file
// type
API.prototype.fileDownload = async function(callConfig, callbackAction=null){
    try {
        const config = this._setConfig(callConfig);
        return await axios(config)
            .then(response => {
                return {response: response}
            })
            .catch(error => this._handleError({error, callbackAction})); 

    } catch(error){
        const message = 'Error caught in API/fileDownload';
        catchBlockErrors({error, message});
    }

};

API.prototype.nonIM = async function(callConfig, callbackAction){
    try {
        const config = this._setConfig(callConfig);
        return await this._axios({config, callbackAction, immutableResponse:false});
    } catch(error){
        const message = "Error caught in API_m/subscribe";
        catchBlockErrors({error, message});
    }

};

API.prototype.getToken = function(){
    if (this.token) return this.token;
    else return false;
}

API.prototype._setConfig = function ({ endpoint, method, options}){
    // options are any other property we want pass axios, params, data, callback
    // functions, anything. 
    // options are {key: value}, key being the property key for axios, ie data
    // so example. 
    // options: {
    //      data: {airportID, workOrderID},
    //      onUploadProgress: uploadProgressCallback
    //  }
    //
    try{
        const config = {
            url: endpoint,
            method: method,
            baseURL: baseURL,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.token}`,
            },
        };

        // loop through our options and set them as properties
        for (const key in options) config[key] = options[key];

        return config;

    } catch(error){
        const message = 'Error caught in API_m/_setConfig'
        catchBlockErrors({error, message});
    }
};

// callbackAction is a redux action update passed in when we call subscribe
// we default it to null so we don't have to call it when it isn't avaliable 
API.prototype._axios = async function({config, callbackAction=null, immutableResponse=true}){
    try{
        return await axios(config)
            .then(response => {
                return this._handleSuccess({
                    data: response.data, 
                    callbackAction,
                    immutableResponse
                })
            })
            .catch(error => this._handleError({error, callbackAction})); 
    } catch(error){
        const message = 'Error caught in API_m/_axios'
        catchBlockErrors({error, message}) 
    }
};

API.prototype._displaySnackBarFeedback = function(notification){
    this.dispatch(showSnackbar(notification));
};

API.prototype._handleSuccess = async function({data, callbackAction, immutableResponse}){
    try {
        // if a callbackFunction was passed the it is a dispatchable action creator
        // for the redux store
        // we also turn the data into an immutable record here so if for cases
        // where we actually don't want immtuable data structor, use call then
        // dispatch an action with the response if it needs to be saved in
        // redux.
        // an example of that situation would be main and sub features, these
        // objects are much too large to turn immutable
        // responses are also shaped like data: data so if we want to set the
        // data in redux from here we need to pull it out. the check is just
        // in case some endpoints aren't shaped correctly
        const _data = data.data ? data.data : data;
        const responseData = immutableResponse ? Immutable.fromJS(_data) : _data;

        if(typeof callbackAction === 'function') {
            this.dispatch(callbackAction(responseData))
        }
        // we can can drop the callbackAction
        // if the backend returns a action object like say
        // data.action_creator : {type: 'assess/updateKeyValuePair', key, value };
        // we could do
        if (data.message && data.meessage  !== null) {
            this._displaySnackBarFeedback({
                message: data.message,
                variant: 'success', 
            });
        }
        return responseData;
    } catch(error){
        const message = 'Error caught in API_m/_handleSuccess'
        catchBlockErrors({error, message})
    }
};

API.prototype._handleError = async function({error, callbackAction}){
    try{
        // if there is an error message from the backend pull it out and report it
        // to sentry
        // const _error = error.response && error.response.data ? error.response.data : error;
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // if(error.response && error.response.user_feedback){

        if (error.response) {
            const immutableError = Immutable.fromJS(error.response);
            const userFeedback = immutableError.getIn(
                ['data', 'message'], null
            );

            const errorCode = immutableError.getIn(
                ['data', 'detail', 'code'], null
            ); 
            // if token expired redirect them back to the login page
            if ( errorCode !== null && errorCode === "token_expired" ){
                window.location.reload();
                return {error: error};
            };

            if ( userFeedback && typeof userFeedback === 'string') {
                this._displaySnackBarFeedback({
                    message: userFeedback,
                    variant: 'error', 
                    persist: true,
                });
            }

            reportAPIErrorToSentry({
                backendResponse: immutableError.toJS(),
                category: error.config.method,
                token: this.token,
                error,
            });

            // we return an {error: error} structure so we can easily check for
            // apiResponse.error to see if an error happend from a component
            return {error: error};
        } else {
            // the call was dropped or for some reason there was no respons
            // this._displaySnackBarFeedback({
            //     message: 'Network Error',
            //     variant: 'error',
            //     persist: true
            // });
            return { error: error };
        }
    } catch(error){
        const message = 'Error caught in API_m/_handleError'
        catchBlockErrors({error, message})
    }

};

API.prototype._repeatCall = async function({error}){
    try{
        // check if we are online, and repeate until we have connection again.
        this.runningRepeat = true;
        let isOnline = navigator.onLine;
        if(!isOnline) setTimeout(async() => await this._repeatCall({error}), 500)
        else {
            const promises = this.pendingCalls.map(async(error) => {
                const config = error.config;
                const callbackAction = error.callbackAction;
                await this._axios({config, callbackAction})
                    .finally(() => {
                        this.dispatch({ type: 'app/updatePendingCallsArray', error });
                        this._updatePendingCallsArray(error);
                    })
            })
            this.runningRepeat = false;
            return Promise.all(promises);
        }
    } catch(error){
        const message = 'Error caught in API_m/_repeatCall'
        catchBlockErrors({error, message})
    }
};

API.prototype._updatePendingCallsArray = function(error){
    try{
        let updateMethod;
        let argForMethod = error; 
        if(!this.pendingCalls.includes(error)) updateMethod = 'push'
        else { 
            updateMethod = 'delete';
            argForMethod = this.pendingCalls.indexOf(error);
        }

        this.pendingCalls = this.pendingCalls[updateMethod](argForMethod);
    } catch(error){
        const message = 'Error caught in API_m/_updatePendingCallsArray'
        catchBlockErrors({error, message})
    }
};

export default API;
